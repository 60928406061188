<template>
    <div v-if="showHd" class="hhd">
        <van-nav-bar :title="titles" :fixed="true" :placeholder="true" :left-text="back_text" :left-arrow="back_btn" @click-left="back">
            <template #right>
                <van-cell title="分享" @click="showSharePop" class="hd-share" v-if="has_share">
                    <template #icon><van-icon :name="require('../assets/icon/share_icon.png')"/></template>
                </van-cell>
            </template>
        </van-nav-bar>

        <van-share-sheet
                v-model:show="showShare"
                title="立即分享给好友"
                :options="options"
                @select="onSelect"
        />
    </div>
</template>

<style scoped>
    .hhd{
        height: 46px;
    }
    .hd-share{
        color: var(--van-nav-bar-icon-color);
    }
    .hd-share :deep(img){
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
        display: inline-block;
        margin-right: 5px;
    }
    .van-haptics-feedback>.hd-share{
        padding: 0;
    }
</style>

<script>
    import { showToast } from 'vant';
    import * as clipboard from "clipboard-polyfill";
    export default {
        data(){
            return {
                options:[
                    { name: '复制链接', icon: 'link',className:'fenx'},
                ],
                showShare:false,//点击分享后的弹窗
                create_share_data:{},
            };
        },
        name: 'HeadNav',
        props: {
            msg: String,
            titles:String,
            shareData:Object,//分享的数据
            shareDataExt:Object,
            RouteData:String,//接收分享路由的数据
            showHd:Boolean,
            back_text:String,//返回文字
            back_btn:Boolean,//左侧返回按钮
            has_share:{default:true,type:Boolean},//右侧分享按钮
        },
        methods:{
            showSharePop(){
                const that=this;
                let shareData=this.$route.params;
                let RouteData=this.$route.name;
                if(this.RouteData)RouteData=this.RouteData;
                if(this.shareData)shareData=this.shareData;
                //console.log(this.shareData)
                this.$http.post("/create_share",{route_name:RouteData,params:shareData}).then(function(data){
                    if(data.errcode==0){
                        that.create_share_data=data;
                        that.showShare = true;
                    }else{
                        showToast(data.errmsg);
                        that.showShare = false;
                    }
                })
            },
            onSelect(){
                const that=this;

                //console.log(this.shareData,this.$route)
                //let shareData=this.$route.params;
                let RouteData=this.$route.name;
                if(this.RouteData)RouteData=this.RouteData;
                //if(this.shareData)shareData=this.shareData;
                let data=this.create_share_data;

                let share_url="http://"+window.location.host+"/"+data.data;
                that.copyText(share_url);

                //如果是收藏的分享，本地数据中要增加分享数据（需重复过滤）
                if(RouteData=='collect'){
                    let PutShare=JSON.parse(localStorage.getItem("PutShare"))||[];
                    let in_share=false;
                    for(let idx in PutShare){
                        if(PutShare[idx].id==data.sid)in_share=true;
                    }
                    if(in_share==false){//没有重复的才能增加
                        let storage_data={
                            id:data.sid,
                            data:that.shareDataExt,
                            info:"共计"+that.shareDataExt.length+"件商标",
                            share_time:data.share_time,
                        };
                        PutShare=PutShare.concat(storage_data);
                        localStorage.setItem("PutShare",JSON.stringify(PutShare));
                        that.$emit('updatePutCollect')
                    }
                }
                that.showShare = false;
            },
            back(){
                //console.log(this.$router)
                this.$router.go(-1)
            },
            copyText(text) {
                clipboard.writeText(text).then(()=>{
                    showToast('链接已复制');
                },()=>{
                    showToast('复制到剪贴板失败');
                })
            },

        },
    }
</script>
