import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CollectView from '../views/CollectView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes:[
    {
      path: '/:share_code(\\w+)?',
      name: 'home',
      component: HomeView,
      meta: {title: '找标库',keepAlive:true},
    },
    {
      path: '/tmlist/:type',
      name: 'tmlist',
      meta:{title: '商标列表',keepAlive:true},
      component: () => import('../views/TmListView.vue')
    },
    {
      //path: '/search/:keyword?/t/:type*/ft/:font_type?/fnt/:fontnum_type?/pt/:price_type?/rt/:regdate_type?/tt/:tjtj_type?',
      path: '/search',
      name: 'search',
      component: () => import('../views/SearchView.vue'),
      meta:{title: '商标搜索',keepAlive:true}
    },
    {
      path: '/detail/:id',
      name: 'detail',
      meta:{title: '商标详情'},
      component: () => import('../views/detailView.vue')
    },
    {
      path: '/collect/:sid?',
      name: 'collect',
      component: CollectView,
      meta:{title: '我的收藏',keepAlive:true}
    },
    {
      path: '/PutShareList/:id',
      name: 'PutShareList',
      meta:{title: '我的分享商标'},
      component: () => import('../views/PutShareListView.vue')
    },
    {
      path: '/GetShareList/:id',
      name: 'GetShareList',
      meta:{title: '收到的分享商标'},
      component: () => import('../views/GetShareListView.vue')
    },
    // 路由页面
    {
      path: '/404',
      name: '404page',
      meta:{title: '404'},
      component: () => import('../views/404/indexView.vue'),
    },
    // 未知路由重定向
    {
      path: '/:pathMatch(.*)',
      redirect: '/404',
      hidden: true
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    //console.log('pos: ', savedPosition)
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
})

export default router
